/**
 * Labstep
 *
 * @module components/User/Table
 * @desc Table listing all users
 */

import React from 'react';
import EntityTable from 'labstep-web/components/Entity/Table';
import UserGroupActionUpdate from 'labstep-web/components/UserGroup/Action/Update';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import { UserGroup } from 'labstep-web/models/user-group.model';
import Profile from 'labstep-web/components/User/Show/Profile';
import Hoverable from 'labstep-web/core/Hoverable';
import PermissionRoleActionViewAll from 'labstep-web/components/PermissionRole/Action/ViewAll';
import { IUserGroupTableProps } from './types';

export const UserGroupTable: React.FC<IUserGroupTableProps> = ({
  userGroups,
  actionMenu,
  ...rest
}) => {
  return (
    <EntityTable
      {...rest}
      id="user-table"
      primaryColumn={{
        header: 'Name',
        content: (userGroup) => (
          <Profile
            user={userGroup.user}
            viewComponent={
              <EntityLinkComponent entity={userGroup.user} />
            }
          />
        ),
        cellProps: { style: { flex: 2 } },
      }}
      extraColumns={[
        {
          header: 'Workspace Role',
          content: (userGroup: UserGroup) => (
            <Hoverable
              hoverable={
                <UserGroupActionUpdate userGroup={userGroup} />
              }
              component={<PermissionRoleActionViewAll />}
            />
          ),
          cellProps: { style: { flex: 2 } },
        },
      ]}
      entities={userGroups}
      actionMenu={actionMenu}
    />
  );
};

export default UserGroupTable;
