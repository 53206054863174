/**
 * Labstep
 *
 * @module components/ResourceItem/ResourceLocationModal/Content
 * @desc Content of modal to view and edit resource location
 */

import Container from 'labstep-web/components/Layout/Container';
import ResourceItemFormShowEditResourceLocation from 'labstep-web/components/ResourceItem/Form/ShowEdit/ResourceLocation';
import ResourceLocationCardEmptyStateMap from 'labstep-web/components/ResourceLocation/Card/EmptyState/Map';
import ResourceLocationMapModalContent from 'labstep-web/components/ResourceLocation/MapModal/Content';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IResourceItemResourceLocationModalContentProps } from './types';

export const ResourceItemResourceLocationModalContent: React.FC<
  IResourceItemResourceLocationModalContentProps
> = ({ resourceItem, hideMap }) => {
  const itemLocationId =
    resourceItem.resource_location &&
    resourceItem.resource_location.id;

  const [resourceLocationId, setResourceLocationId] =
    useState(itemLocationId);

  useEffect(() => {
    setResourceLocationId(itemLocationId);
  }, [itemLocationId]);

  // https://github.com/Labstep/web/issues/7132
  if (resourceItem.is_template) {
    return (
      <ResourceItemFormShowEditResourceLocation
        resourceItem={resourceItem}
        initialEditingState={!resourceItem.resource_location}
        selected={resourceLocationId}
        onSelect={setResourceLocationId}
      />
    );
  }

  const isInnerLocation = itemLocationId === resourceLocationId;

  const initialSelectedState = isInnerLocation
    ? `${resourceItem.entityName}-${resourceItem.id}`
    : null;

  const initialSelectionInfo = isInnerLocation
    ? {
        name: resourceItem.name,
      }
    : null;

  return (
    <div className={styles.container}>
      <Container>
        <ResourceItemFormShowEditResourceLocation
          resourceItem={resourceItem}
          initialEditingState={!resourceItem.resource_location}
          selected={resourceLocationId}
          onSelect={setResourceLocationId}
        />
      </Container>
      {resourceLocationId && !hideMap && (
        <ReadOnMountHOC
          type="entities"
          params={{
            get_single: 1,
            id: resourceLocationId,
          }}
          entityName={ResourceLocation.entityName}
          loading={{ loader: 'placeholder', cached: true }}
          children={({ entity }) =>
            entity.map_data ? (
              <ResourceLocationMapModalContent
                key={resourceLocationId}
                resourceLocation={entity}
                initialSelectedState={initialSelectedState}
                initialSelectionInfo={initialSelectionInfo}
              />
            ) : (
              <ResourceLocationCardEmptyStateMap
                resourceLocation={entity}
                actionComponentProps={{
                  type: 'button',
                  elementProps: { basic: true },
                }}
              />
            )
          }
        />
      )}
    </div>
  );
};

export default ResourceItemResourceLocationModalContent;
