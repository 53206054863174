/**
 * Labstep
 *
 * @module state/services/schema
 * @desc Combine all redux actions using normalizr
 */

/* tslint:disable:prefer-array-literal */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { AccessKey } from 'labstep-web/models/access-key.model';
import { Automation } from 'labstep-web/models/automation.model';
import { Chemical } from 'labstep-web/models/chemical.model';
import { Comment } from 'labstep-web/models/comment.model';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { DataTable } from 'labstep-web/models/data-table.model';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import { DeviceData } from 'labstep-web/models/device-data.model';
import { Device } from 'labstep-web/models/device.model';
import { EntityExport } from 'labstep-web/models/entity-export.model';
import { EntityImport } from 'labstep-web/models/entity-import.model';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { EntityUserRoleRequirement } from 'labstep-web/models/entity-user-role-requirement.model';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ExperimentWorkflowLink } from 'labstep-web/models/experiment-workflow-link.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { File } from 'labstep-web/models/file.model';
import { Folder } from 'labstep-web/models/folder.model';
import { GroupAutoSharing } from 'labstep-web/models/group-auto-sharing.model';
import { Group } from 'labstep-web/models/group.model';
import { JupyterInstance } from 'labstep-web/models/jupyter-instance.model';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import { Log } from 'labstep-web/models/log.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { NotificationAlert } from 'labstep-web/models/notification-alert.model';
import { Notification } from 'labstep-web/models/notification.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { OrganizationInvoice } from 'labstep-web/models/organization-invoice.model';
import { OrganizationPlanPrice } from 'labstep-web/models/organization-plan-price.model';
import { OrganizationPlan } from 'labstep-web/models/organization-plan.model';
import { OrganizationSAML } from 'labstep-web/models/organization-saml.model';
import { Organization } from 'labstep-web/models/organization.model';
import { OutboundOrderRequest } from 'labstep-web/models/outbound-order-request.model';
import { OutboundPurchaseOrder } from 'labstep-web/models/outbound-purchase-order.model';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import { PermissionRoleSetting } from 'labstep-web/models/permission-role-setting.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import { Permission } from 'labstep-web/models/permission.model';
import { Profile } from 'labstep-web/models/profile.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { ProtocolDevice } from 'labstep-web/models/protocol-device.model';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { ResearchArea } from 'labstep-web/models/research-area.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { Resource } from 'labstep-web/models/resource.model';
import { RichText } from 'labstep-web/models/rich-text.model';
import { SecurityLog } from 'labstep-web/models/security-log.model';
import { Sequence } from 'labstep-web/models/sequence.model';
import { ShareLinkInvitation } from 'labstep-web/models/share-link-invitation.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { SignatureRequest } from 'labstep-web/models/signature-request.model';
import { SignatureRequirement } from 'labstep-web/models/signature-requirement.model';
import { Signature } from 'labstep-web/models/signature.model';
import { Tag } from 'labstep-web/models/tag.model';
import { Thread } from 'labstep-web/models/thread.model';
import { UserGroupNotification } from 'labstep-web/models/user-group-notification.model';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { UserMention } from 'labstep-web/models/user-mention.model';
import { UserOrganization } from 'labstep-web/models/user-organization.model';
import { UserShareLink } from 'labstep-web/models/user-share-link.model';
import { User } from 'labstep-web/models/user.model';
import { schema } from 'labstep-web/state/normalizr';
// AccessKey
export const accessKey: schema.Entity = new schema.Entity(
  'access_key',
);
accessKey.EntityClass = AccessKey;
export const accessKeys: schema.Array = new schema.Array(accessKey);

// Automation
export const automation: schema.Entity = new schema.Entity(
  'automation',
);
automation.EntityClass = Automation;
export const automations: schema.Array = new schema.Array(automation);

// Chemical
export const chemical: schema.Entity = new schema.Entity(
  'chemical',
  {},
  { idAttribute: Chemical.idAttr },
);
chemical.EntityClass = Chemical;
export const chemicals: schema.Array = new schema.Array(chemical);
// Custom Identifier Set
export const customIdentifierSet: schema.Entity = new schema.Entity(
  'custom_identifier_set',
);
customIdentifierSet.EntityClass = CustomIdentifierSet;
export const customIdentifierSets: schema.Array = new schema.Array(
  customIdentifierSet,
);
// DataTable
export const dataTable: schema.Entity = new schema.Entity(
  'data_table',
);
dataTable.EntityClass = DataTable;
export const dataTables: schema.Array = new schema.Array(dataTable);
// RichText
export const richText: schema.Entity = new schema.Entity('rich_text');
richText.EntityClass = RichText;
export const richTexts: schema.Array = new schema.Array(richText);
// Molecule
export const molecule: schema.Entity = new schema.Entity(
  'molecule',
  {},
  { idAttribute: Molecule.idAttr },
);
molecule.EntityClass = Molecule;
export const molecules: schema.Array = new schema.Array(molecule);
// Comments
export const comment: schema.Entity = new schema.Entity('comment');
comment.EntityClass = Comment;
export const comments: schema.Array = new schema.Array(comment);
// Experiments
export const experiment: schema.Entity = new schema.Entity(
  'experiment',
);
experiment.EntityClass = Experiment;
export const experiments: schema.Array = new schema.Array(experiment);
// Experiments
export const experimentWorkflow: schema.Entity = new schema.Entity(
  'experiment_workflow',
);
experimentWorkflow.EntityClass = ExperimentWorkflow;
export const experimentWorkflows: schema.Array = new schema.Array(
  experimentWorkflow,
);
// EntityImport
export const entityImport: schema.Entity = new schema.Entity(
  'entity_import',
  {},
  { idAttribute: EntityImport.idAttr },
);
entityImport.EntityClass = EntityImport;
export const entityImports: schema.Array = new schema.Array(
  entityImport,
);
// EntityExport
export const entityExport: schema.Entity = new schema.Entity(
  'entity_export',
);
entityExport.EntityClass = EntityExport;
export const entityExports: schema.Array = new schema.Array(
  entityExport,
);
// EntityState
export const entityState: Schema.Entity = new schema.Entity(
  'entity_state',
);
export const entityStates: Schema.Array = new schema.Array(
  entityState,
);
entityState.EntityClass = EntityState;
// EntityStateWorkflow
export const entityStateWorkflow: Schema.Entity = new schema.Entity(
  'entity_state_workflow',
);
export const entityStateWorkflows: Schema.Array = new schema.Array(
  entityStateWorkflow,
);
entityStateWorkflow.EntityClass = EntityStateWorkflow;
// Entity User
export const entityUser: schema.Entity = new schema.Entity(
  'entity_user',
);
// Entity User Role
export const entityUserRole: schema.Entity = new schema.Entity(
  'entity_user_role',
);
export const entityUserRoles: schema.Array = new schema.Array(
  entityUserRole,
);
entityUserRole.EntityClass = EntityUserRole;
// Entity User Role Requirement
export const entityUserRoleRequirement: schema.Entity =
  new schema.Entity('entity_user_role_requirement');
export const entityUserRoleRequirements: schema.Array =
  new schema.Array(entityUserRoleRequirement);
entityUserRoleRequirement.EntityClass = EntityUserRoleRequirement;
entityUser.EntityClass = EntityUser;
export const entityUsers: schema.Array = new schema.Array(entityUser);
// Entity View
export const entityView: schema.Entity = new schema.Entity(
  'entity_view',
  {},
  { idAttribute: EntityView.idAttr },
);
entityView.EntityClass = EntityView;
export const entityViews: schema.Array = new schema.Array(entityView);
// Files
export const file: schema.Entity = new schema.Entity('file');
file.EntityClass = File;
export const files: schema.Array = new schema.Array(file);
// Folders
export const folder: schema.Entity = new schema.Entity('folder');
folder.EntityClass = Folder;
export const folders: schema.Array = new schema.Array(folder);
// Groups
export const group: schema.Entity = new schema.Entity('group');
group.EntityClass = Group;
export const groups: schema.Array = new schema.Array(group);
// GroupAutoSharing
export const groupAutoSharing: schema.Entity = new schema.Entity(
  'group_auto_sharing',
  {},
  { idAttribute: GroupAutoSharing.idAttr },
);
groupAutoSharing.EntityClass = GroupAutoSharing;
export const groupAutoSharings: schema.Array = new schema.Array(
  groupAutoSharing,
);
// Device
export const device: schema.Entity = new schema.Entity('device');
device.EntityClass = Device;
export const devices: schema.Array = new schema.Array(device);
// Device Data
export const deviceData: schema.Entity = new schema.Entity(
  'device_data',
);
deviceData.EntityClass = DeviceData;
export const deviceDatas: schema.Array = new schema.Array(deviceData);
// Device Booking
export const deviceBooking: schema.Entity = new schema.Entity(
  'device_booking',
);
deviceBooking.EntityClass = DeviceBooking;
export const deviceBookings: schema.Array = new schema.Array(
  deviceBooking,
);
// Experiment Workflow Link
export const experimentWorkflowLink: schema.Entity =
  new schema.Entity(
    'experiment_workflow_link',
    {},
    { idAttribute: ExperimentWorkflowLink.idAttr },
  );
experimentWorkflowLink.EntityClass = ExperimentWorkflowLink;
export const experimentWorkflowLinks: schema.Array = new schema.Array(
  experimentWorkflowLink,
);
// Jupyter Instance
export const jupyterInstance: schema.Entity = new schema.Entity(
  'jupyter_instance',
  {},
  { idAttribute: JupyterInstance.idAttr },
);
jupyterInstance.EntityClass = JupyterInstance;
export const jupyterInstances: schema.Array = new schema.Array(
  jupyterInstance,
);
// Jupyter Notebook
export const jupyterNotebook: schema.Entity = new schema.Entity(
  'jupyter_notebook',
  {},
  { idAttribute: JupyterNotebook.idAttr },
);
jupyterNotebook.EntityClass = JupyterNotebook;
export const jupyterNotebooks: schema.Array = new schema.Array(
  jupyterNotebook,
);
// Log
export const log: schema.Entity = new schema.Entity('log');
log.EntityClass = Log;
export const logs: schema.Array = new schema.Array(log);
// LogEntry
export const logEntry: schema.Entity = new schema.Entity('log_entry');
logEntry.EntityClass = Log;
export const logEntries: schema.Array = new schema.Array(logEntry);
// Notifications
export const notification: schema.Entity = new schema.Entity(
  'notification',
);
notification.EntityClass = Notification;
export const notifications: schema.Array = new schema.Array(
  notification,
);
// NotificationAlert
export const notificationAlert: schema.Entity = new schema.Entity(
  'notification_alert',
  {},
  { idAttribute: NotificationAlert.idAttr },
);
notificationAlert.EntityClass = NotificationAlert;
export const notificationAlerts: schema.Array = new schema.Array(
  notificationAlert,
);
// Order
export const purchaseOrder: schema.Entity = new schema.Entity(
  'purchase_order',
);
purchaseOrder.EntityClass = PurchaseOrder;
export const purchaseOrders: schema.Array = new schema.Array(
  purchaseOrder,
);
// Order Request
export const orderRequest: schema.Entity = new schema.Entity(
  'order_request',
);
orderRequest.EntityClass = OrderRequest;
export const orderRequests: schema.Array = new schema.Array(
  orderRequest,
);
// Organization
export const organization: schema.Entity = new schema.Entity(
  'organization',
);
organization.EntityClass = Organization;
export const organizations: schema.Array = new schema.Array(
  organization,
);
// OrganizationInvoice
export const organizationInvoice: schema.Entity = new schema.Entity(
  'organization_invoice',
  {},
  { idAttribute: OrganizationInvoice.idAttr },
);
organizationInvoice.EntityClass = OrganizationInvoice;
export const organizationInvoices: schema.Array = new schema.Array(
  organizationInvoice,
);
// OrganizationPlan
export const organizationPlan: schema.Entity = new schema.Entity(
  'organization_plan',
);
organizationPlan.EntityClass = OrganizationPlan;
export const organizationPlans: schema.Array = new schema.Array(
  organizationPlan,
);
// OrganizationPlanPrice
export const organizationPlanPrice: schema.Entity = new schema.Entity(
  'organization_plan_price',
);
organizationPlanPrice.EntityClass = OrganizationPlanPrice;
export const organizationPlanPrices: schema.Array = new schema.Array(
  organizationPlanPrice,
);
// OrganizationSAML
export const organizationSaml: schema.Entity = new schema.Entity(
  'organization_saml',
  {},
  { idAttribute: OrganizationSAML.idAttr },
);
organizationSaml.EntityClass = OrganizationSAML;
// OutboundOrderRequest
export const outboundOrderRequest: schema.Entity = new schema.Entity(
  'outbound_order_request',
);
outboundOrderRequest.EntityClass = OutboundOrderRequest;
export const outboundOrderRequests: schema.Array = new schema.Array(
  outboundOrderRequest,
);
// OutboundPurchaseOrder
export const outboundPurchaseOrder: schema.Entity = new schema.Entity(
  'outbound_purchase_order',
);
outboundPurchaseOrder.EntityClass = OutboundPurchaseOrder;
export const outboundPurchaseOrders: schema.Array = new schema.Array(
  outboundPurchaseOrder,
);
// PermaLink
export const permaLink: schema.Entity = new schema.Entity(
  'perma_link',
  {},
  { idAttribute: PermaLink.idAttr },
);
permaLink.EntityClass = PermaLink;
export const permaLinks: schema.Array = new schema.Array(permaLink);
// Permission
export const permission: schema.Entity = new schema.Entity(
  'permission',
  {},
  { idAttribute: Permission.idAttr },
);
permission.EntityClass = Permission;
export const permissions: schema.Array = new schema.Array(permission);
// PermissionRole
export const permissionRole: schema.Entity = new schema.Entity(
  'permission_role',
  {},
  { idAttribute: PermissionRole.idAttr },
);
permissionRole.EntityClass = PermissionRole;
export const permissionRoles: schema.Array = new schema.Array(
  permissionRole,
);
// PermissionRoleSetting
export const permissionRoleSetting: schema.Entity = new schema.Entity(
  'permission_role_setting',
  {},
  { idAttribute: PermissionRoleSetting.idAttr },
);
permissionRoleSetting.EntityClass = PermissionRoleSetting;
export const permissionRoleSettings: schema.Array = new schema.Array(
  permissionRoleSetting,
);
// Profiles
export const profile: schema.Entity = new schema.Entity('profile');
profile.EntityClass = Profile;
export const profiles: schema.Array = new schema.Array(profile);
// Protocols
export const protocol: schema.Entity = new schema.Entity('protocol');
protocol.EntityClass = Protocol;
export const protocols: schema.Array = new schema.Array(protocol);
// Protocol Collections
export const protocolCollection: schema.Entity = new schema.Entity(
  'protocol_collection',
);
protocolCollection.EntityClass = ProtocolCollection;
export const protocolCollections: schema.Array = new schema.Array(
  protocolCollection,
);
// ProtocolTables
export const protocolTable: schema.Entity = new schema.Entity(
  'protocol_table',
  {},
  { idAttribute: ProtocolTable.idAttr },
);
protocolTable.EntityClass = ProtocolTable;
export const protocolTables: schema.Array = new schema.Array(
  protocolTable,
);
// Protocol Timers
export const protocolTimer: schema.Entity = new schema.Entity(
  'protocol_timer',
  {},
  { idAttribute: ProtocolTimer.idAttr },
);
protocolTimer.EntityClass = ProtocolTimer;
export const protocolTimers: schema.Array = new schema.Array(
  protocolTimer,
);
// Protocol Values
export const protocolValue: schema.Entity = new schema.Entity(
  'protocol_value',
  {},
  { idAttribute: ProtocolValue.idAttr },
);
protocolValue.EntityClass = ProtocolValue;
export const protocolValues: schema.Array = new schema.Array(
  protocolValue,
);
// Protocol Steps
export const protocolStep: schema.Entity = new schema.Entity(
  'protocol_step',
  {},
  { idAttribute: ProtocolStep.idAttr },
);
protocolStep.EntityClass = ProtocolStep;
export const protocolSteps: schema.Array = new schema.Array(
  protocolStep,
);
// Protocol Devices
export const protocolDevice: schema.Entity = new schema.Entity(
  'protocol_device',
  {},
  { idAttribute: ProtocolDevice.idAttr },
);
protocolDevice.EntityClass = ProtocolDevice;
export const protocolDevices: schema.Array = new schema.Array(
  protocolDevice,
);
// Protocol Condition
export const protocolCondition: schema.Entity = new schema.Entity(
  'protocol_condition',
  {},
  { idAttribute: ProtocolCondition.idAttr },
);
protocolCondition.EntityClass = ProtocolCondition;
export const protocolConditions: schema.Array = new schema.Array(
  protocolCondition,
);
// Resource
export const resource: schema.Entity = new schema.Entity('resource');
resource.EntityClass = Resource;
export const resources: schema.Array = new schema.Array(resource);
// Metadata
export const metadata: schema.Entity = new schema.Entity('metadata');
metadata.EntityClass = Metadata;
export const metadatas: schema.Array = new schema.Array(metadata);
// Metadata
export const metadataThread: schema.Entity = new schema.Entity(
  'metadata_thread',
);
metadataThread.EntityClass = MetadataThread;
export const metadataThreads: schema.Array = new schema.Array(
  metadataThread,
);
// Resource Location
export const resourceLocation: schema.Entity = new schema.Entity(
  'resource_location',
  {},
  { idAttribute: ResourceLocation.idAttr },
);
resourceLocation.EntityClass = ResourceLocation;
export const resourceLocations: schema.Array = new schema.Array(
  resourceLocation,
);
// Resource Item
export const resourceItem: schema.Entity = new schema.Entity(
  'resource_item',
);
resourceItem.EntityClass = ResourceItem;
export const resourceItems: schema.Array = new schema.Array(
  resourceItem,
);
// Research Areas
export const researchArea: schema.Entity = new schema.Entity(
  'research_area',
);
researchArea.EntityClass = ResearchArea;
export const researchAreas: schema.Array = new schema.Array(
  researchArea,
);
// Sequence
export const sequence: schema.Entity = new schema.Entity('sequence');
sequence.EntityClass = Sequence;
export const sequences: schema.Array = new schema.Array(sequence);
// SecurityLog
export const securityLog: schema.Entity = new schema.Entity(
  'security_log',
);
securityLog.EntityClass = SecurityLog;
export const securityLogs: schema.Array = new schema.Array(
  securityLog,
);
// ShareLink
export const shareLink: schema.Entity = new schema.Entity(
  'share_link',
);
shareLink.EntityClass = ShareLink;
export const shareLinks: schema.Array = new schema.Array(shareLink);
// ShareLink
export const shareLinkInvitation: schema.Entity = new schema.Entity(
  'share_link_invitation',
);
shareLinkInvitation.EntityClass = ShareLinkInvitation;
export const shareLinkInvitations: schema.Array = new schema.Array(
  shareLinkInvitation,
);
// Signature
export const signature: schema.Entity = new schema.Entity(
  'signature',
);
signature.EntityClass = Signature;
export const signatures: schema.Array = new schema.Array(signature);
// Signature Request
export const signatureRequest: schema.Entity = new schema.Entity(
  'signature_request',
);
signatureRequest.EntityClass = SignatureRequest;
export const signatureRequests: schema.Array = new schema.Array(
  signatureRequest,
);
// Signature Requirement
export const signatureRequirement: schema.Entity = new schema.Entity(
  'signature_requirement',
);
export const signatureRequirements: schema.Entity = new schema.Array(
  signatureRequirement,
);
signatureRequirement.EntityClass = SignatureRequirement;
// Threads
export const tag: schema.Entity = new schema.Entity('tag');
tag.EntityClass = Tag;
export const tags: schema.Array = new schema.Array(tag);
// Threads
export const thread: schema.Entity = new schema.Entity('thread');
thread.EntityClass = Thread;
export const threads: schema.Array = new schema.Array(thread);
// UserGroup
export const userGroup: schema.Entity = new schema.Entity(
  'user_group',
);
userGroup.EntityClass = UserGroup;
export const userGroups: schema.Array = new schema.Array(userGroup);
// UserGroupNotification
export const userGroupNotification: schema.Entity = new schema.Entity(
  'user_group_notification',
);
userGroupNotification.EntityClass = UserGroupNotification;
export const userGroupNotifications: schema.Array = new schema.Array(
  userGroupNotification,
);
// UserOrganization
export const userOrganization: schema.Entity = new schema.Entity(
  'user_organization',
);
userOrganization.EntityClass = UserOrganization;
export const userOrganizations: schema.Array = new schema.Array(
  userOrganization,
);
// Users
export const user: schema.Entity = new schema.Entity('user');
user.EntityClass = User;
export const users: schema.Array = new schema.Array(user);
// UserMention
export const userMention: schema.Entity = new schema.Entity(
  'user_mention',
);
export const userMentions: schema.Array = new schema.Array(
  userMention,
);
userMention.EntityClass = UserMention;
// UserShareLink
export const userShareLink: schema.Entity = new schema.Entity(
  'user_share_link',
);
export const userShareLinks: schema.Array = new schema.Array(
  userShareLink,
);
userShareLink.EntityClass = UserShareLink;

//
/* DEFINITIONS */
automation.define({
  author: user,
  owner: group,
});
chemical.define({
  protocol_value: protocolValue,
  molecule,
});
comment.define({
  author: user,
  files,
  parent_thread: thread,
  thread,
  data_table: dataTable,
  rich_text: richText,
  molecule,
  resource,
  sequence,
  user_mentions: userMentions,
});
customIdentifierSet.define({
  group,
});
// Experiment
experiment.define({
  thread,
  protocol_steps: protocolSteps,
  protocol_values: protocolValues,
  protocol_tables: protocolTables,
  protocol_timers: protocolTimers,
  jupyter_notebooks: jupyterNotebooks,
  protocol_devices: protocolDevices,
  molecules,
  protocol,
  author: user,
  files,
  experiment_workflow: experimentWorkflow,
  metadata_thread: metadataThread,
  perma_link: permaLink,
});
// Experiment workflow
experimentWorkflow.define({
  assignees: users,
  owner: group,
  signatures,
  thread,
  author: user,
  experiments,
  tags,
  share_link: shareLink,
  metadata_thread: metadataThread,
  root_experiment: experiment,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
  forward_links: experimentWorkflowLinks,
  back_links: experimentWorkflowLinks,
  locked_log: log,
  entity_state_workflow: entityStateWorkflow,
  entity_state: entityState,
  signature_requests: signatureRequests,
});
// Experiment Workflow Link
experimentWorkflowLink.define({
  src: experimentWorkflow,
  dest: experimentWorkflow,
});
// File
file.define({
  author: user,
  snapgene_output_png: file,
  snapgene_output_svg: file,
  image_annotation: file,
  annotated_file: file,
});
// Group
group.define({
  author: user,
  protocol_collections: protocolCollections,
  profile,
  organization,
  thread,
  user_groups: userGroups,
  logged_user_user_group: userGroup,
  default_experiment_workflow_template: experimentWorkflow,
  default_order_request_template: orderRequest,
  custom_identifier_sets: customIdentifierSets,
  default_user_group_permission_role: permissionRole,
  entity_user_roles: entityUserRoles,
  default_entity_state_workflow: entityStateWorkflow,
});
// GroupAutoSharing
groupAutoSharing.define({
  source_group: group,
  target_group: group,
});
// Device
device.define({
  template: device,
  owner: group,
  tags,
  author: user,
  share_link: shareLink,
  metadata_thread: metadataThread,
  image: file,
  thread,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
deviceData.define({
  device,
  files,
});
// Device Booking
deviceBooking.define({
  device,
  author: user,
});
entityUser.define({
  user,
  entity_state_workflow: entityStateWorkflow,
  experiment_workflow: experimentWorkflow,
  protocol_collection: protocolCollection,
  resource,
  resource_item: resourceItem,
  resource_location: resourceLocation,
  order_request: orderRequest,
  purchase_order: purchaseOrder,
  device,
  entity_user_role: entityUserRole,
});
// EntityExport
entityExport.define({
  author: user,
  owner: group,
  log_entry_create: logEntry,
});
// EntityImport
entityImport.define({
  device_template: device,
  resource_template: resource,
});
// EntityState
entityState.define({
  entity_state_workflow: entityStateWorkflow,
  signature_requirements: signatureRequirements,
  entity_user_role_requirements: entityUserRoleRequirements,
});
// EntityStateWorkflow
entityStateWorkflow.define({
  owner: group,
  author: user,
  entity_states: entityStates,
  entity_users_preview: entityUsers,
});
// EntityUserRole
entityUserRole.define({
  owner: group,
  author: user,
  entity_users: entityUsers,
});
// EntityUserRoleRequirement
entityUserRoleRequirement.define({
  entity_state: entityState,
  entity_user_role: entityUserRole,
  owner: group,
  author: user,
  signature_requirement: signatureRequirement,
});
// Jupyter Notebook
jupyterNotebook.define({
  experiment,
  jupyter_instance_edit: jupyterInstance,
  jupyter_instance_run: jupyterInstance,
});
// Log
log.define({
  author: user,
});
// Metadata
metadata.define({
  template: metadata,
  author: user,
  files,
  resource,
  molecule,
  thread,
  metadata_thread: metadataThread,
  sequence,
  device_data: deviceData,
  protocol_device: protocolDevice,
  notification_alert: notificationAlert,
  variable_template: metadata,
});

// eslint-disable-next-line no-underscore-dangle
metadata._processStrategy = (
  entity: Record<string, unknown>,
): Record<string, unknown> => {
  if (entity.template) {
    return entity;
  }
  return {
    ...entity,
    template: entity.template_id,
  };
};

// MetadataThread
metadataThread.define({
  experiment_workflow: experimentWorkflow,
  metadatas,
  metadatas_preview: metadatas,
  experiment,
  protocol,
});
// Molecule
molecule.define({
  comment,
  experiment,
  metadata,
  protocol,
  chemicals,
  limiting_chemical: chemical,
});
resourceLocation.define({
  author: user,
  owner: group,
  outer_location: resourceLocation,
  thread,
  image: file,
  metadata_thread: metadataThread,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
// Notification
notification.define({
  author: user,
});

notificationAlert.define({
  metadata,
});
purchaseOrder.define({
  outbound_purchase_order: outboundPurchaseOrder,
  owner: group,
  author: user,
  thread,
  share_link: shareLink,
  metadata_thread: metadataThread,
  order_requests: orderRequests,
  pending_log: log,
  completed_log: log,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
organization.define({
  author: user,
  logged_user_user_organization: userOrganization,
  users,
  permission_roles: permissionRoles,
  organization_saml: organizationSaml,
});
organizationSaml.define({
  organization,
});
orderRequest.define({
  outbound_order_request: outboundOrderRequest,
  owner: group,
  author: user,
  resource,
  thread,
  share_link: shareLink,
  metadata_thread: metadataThread,
  purchase_order: purchaseOrder,
  locked_log: log,
  ordered_log: log,
  approved_log: log,
  back_ordered_log: log,
  received_log: log,
  partially_received_log: log,
  cancelled_log: log,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
  template: orderRequest,
});
outboundPurchaseOrder.define({
  purchase_order: purchaseOrder,
});
outboundOrderRequest.define({
  order_request: orderRequest,
});
// Permission
permission.define({
  group,
});
// PermissionRole
permissionRole.define({
  organization,
  permission_role_settings: permissionRoleSettings,
});
// PermissionRoleSetting
permissionRoleSetting.define({
  permission_role: permissionRole,
});
// Profile
profile.define({
  research_areas: researchAreas,
  photo: file,
});
// Protocol
protocol.define({
  author: user,
  protocol_steps: protocolSteps,
  protocol_timers: protocolTimers,
  protocol_values: protocolValues,
  protocol_tables: protocolTables,
  protocol_devices: protocolDevices,
  files,
  experiments,
  collection: protocolCollection,
  children_protocol_collections: protocolCollections,
  protocol_collection_draft: protocol,
  metadata_thread: metadataThread,
  thread,
  perma_link: permaLink,
  molecules,
});
// Protocol Condition
protocolCondition.define({
  experiment,
  protocol,
  metadatas,
  protocol_values: protocolValues,
});
// Protocol Collection
protocolCollection.define({
  owner: group,
  tags,
  author: user,
  versions: protocols,
  last_version: protocol,
  parent_protocol: protocol,
  draft: protocol,
  share_link: shareLink,
  thread,
  entity_users_preview: entityUsers,
  perma_link: permaLink,
});
// Protocol Step
protocolStep.define({
  protocol_tables: protocolTables,
  protocol_values: protocolValues,
  protocol_timers: protocolTimers,
  files,
  protocol,
  thread,
});
protocolTable.define({
  protocol_step: protocolStep,
});
protocolTimer.define({
  protocol,
  protocol_step: protocolStep,
});
// Protocol Value
protocolValue.define({
  resource,
  protocol_step: protocolStep,
  protocol,
  experiment,
  resource_template: resource,
  resource_item: resourceItem,
  resource_item_output: resourceItem,
  amount_deducted_log: log,
  variable_template: protocolValue,
});
// Protocol Device
protocolDevice.define({
  device,
  protocol,
  experiment,
  metadatas,
});
// Resource
resource.define({
  metadatas_with_template: metadatas,
  owner: group,
  author: user,
  provider: user,
  perma_link: permaLink,
  thread,
  share_link: shareLink,
  image: file,
  template: resource,
  metadata_thread: metadataThread,
  order_request: orderRequest,
  resource_item_template: resourceItem,
  order_request_template: orderRequest,
  entity_users_preview: entityUsers,
  molecule,
  custom_identifier_set: customIdentifierSet,
});
resourceItem.define({
  author: user,
  perma_link: permaLink,
  thread,
  image: file,
  resource_location: resourceLocation,
  resource,
  metadata_thread: metadataThread,
  order_request: orderRequest,
  entity_users_preview: entityUsers,
  protocol_value_origin: protocolValue,
});
// ShareLink
shareLink.define({
  organization,
  author: user,
  experiment_workflow: experimentWorkflow,
  group,
  protocol_collection: protocolCollection,
  resource,
  order_request: orderRequest,
  device,
});
shareLinkInvitation.define({
  share_link: shareLink,
});
signature.define({
  author: user,
  experiment_workflow: experimentWorkflow,
  entity_state: entityState,
});
signatureRequest.define({
  author: user,
  experiment_workflow: experimentWorkflow,
  user,
});
signatureRequirement.define({
  reject_entity_state: entityState,
  signatures,
  signature_requests: signatureRequests,
});
// Tag
tag.define({
  author: user,
});
// Thread
thread.define({
  experiment,
  protocol,
  protocol_step: protocolStep,
  experiment_workflow: experimentWorkflow,
  metadata,
  comments,
});
// User
user.define({
  organization,
  group,
  profile,
  thread,
  user_groups: userGroups,
  user_organizations: userOrganizations,
  metadata_thread: metadataThread,
  entity_user_roles: entityUserRoles,
  entity_user_role_requirements: entityUserRoleRequirements,
});
// UserMention
userMention.define({
  user,
});
// UserGroup
userGroup.define({
  user,
  group,
  permission_role: permissionRole,
  entity_user_roles: entityUserRoles,
});
// UserOrganization
userOrganization.define({
  user,
  organization,
});
userGroupNotification.define({
  user_group: userGroup,
});
// UserShareLink
userShareLink.define({
  user,
  share_link: shareLink,
});
