/**
 * Labstep
 *
 * @module core/TextWithCount
 * @desc Count cirlces
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { ITextWithCountProps } from './types';

export const Count: React.FC<{ count: number }> = ({ count }) => (
  <span className={styles.count}>{count}</span>
);

export const TextWithCount: React.FC<ITextWithCountProps> = ({
  text,
  icon,
  count,
  header,
  iconProps,
  showCountIfZero,
  inverted,
  customIcon,
}) => {
  return (
    <span
      className={classnames({
        [styles.inverted]: inverted,
        [styles.header]: header,
      })}
    >
      {customIcon ||
        (icon && (
          <span>
            <Icon name={icon} {...iconProps} />
          </span>
        ))}
      <span data-public>{text}</span>
      {(!!count || showCountIfZero) && <Count count={count || 0} />}
    </span>
  );
};

export default TextWithCount;
