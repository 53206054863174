import React from 'react';
import { EntityTable } from 'labstep-web/components/Entity/Table';
import Flex from 'labstep-web/core/Flex';
import { Group } from 'labstep-web/models/group.model';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { Size } from 'labstep-web/core/Initials/types';
import Header from 'labstep-web/core/Header';
import Button from 'labstep-web/core/Button';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { Icon } from 'semantic-ui-react';
import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import Modal from 'labstep-web/core/Modal/Default';
import { GroupInitials } from '../Initials';
import GroupActionMenu from '../Action/Menu';
import GroupCardAbout from '../Card/About';
import { IGroupTableProps } from './types';

export const GroupTable: React.FC<IGroupTableProps> = ({
  groups,
  authenticatedUserEntity,
}) => {
  const workspaceEnterCTA = (group: Group, primary?: boolean) => {
    switch (true) {
      case group.logged_user_user_group != null:
        return (
          <Button
            size="small"
            route={{
              to: 'group_overview',
              params: { id: group.id },
            }}
            basic={!primary}
            color="blue"
            style={{ margin: '0' }}
          >
            Enter
          </Button>
        );

      case group.is_invite_only:
        return (
          <Flex
            hAlignContent="right"
            spacing="gap"
            vAlignContent="center"
          >
            <Header size={Size.mini} noMargin>
              <Icon name="lock" />
              Invite Only
            </Header>
          </Flex>
        );

      default:
        return (
          <EntityActionCreate
            entityName={UserGroup.entityName}
            body={{
              user_id: authenticatedUserEntity.id,
              group_id: group.id,
            }}
            redirectRoute={() => ({
              to: 'group_overview',
              params: { id: group.id },
            })}
            actionComponentProps={{
              type: 'button',
              text: 'Join',
              elementProps: {
                size: 'small',
                color: 'blue',
                basic: !primary,
                style: { margin: '0' },
              },
            }}
          />
        );
    }
  };

  const workspaceNameColumn = {
    header: Group.getHumanReadableEntityName(false, true),
    content: (group: Group): React.ReactElement => (
      <Modal
        size="small"
        content={<GroupCardAbout group={group} />}
        viewComponent={({ toggleModal }) => (
          <Flex
            spacing="gap"
            vAlignContent="center"
            onClick={toggleModal}
            style={{ cursor: 'pointer' }}
          >
            <GroupInitials size={Size.mini} group={group} centered />
            <Header size={Size.mini} noMargin>
              {group.name}
            </Header>
          </Flex>
        )}
        footer={
          <Flex spacing="gap" hAlignContent="right">
            {workspaceEnterCTA(group, true)}
            {!group.logged_user_user_group &&
              (authenticatedUserEntity.isOrganizationAdmin ? (
                <EntityActionCreate
                  entityName={UserGroup.entityName}
                  body={{
                    user_id: authenticatedUserEntity.id,
                    group_id: group.id,
                  }}
                  redirectRoute={() => ({
                    to: 'group_overview',
                    params: { id: group.id },
                  })}
                  actionComponentProps={{
                    type: 'button',
                    text: 'Use Admin Permission to Join',
                    elementProps: {
                      size: 'small',
                      basic: true,
                      style: { margin: '0' },
                    },
                  }}
                />
              ) : (
                <p>
                  <Icon name="exclamation triangle" color="yellow" />
                  Contact owner to join
                </p>
              ))}
          </Flex>
        }
      />
    ),
  };

  const workspaceEnterColumn = (group: Group) => {
    return (
      <Flex
        spacing="gap"
        marginRight={20}
        vAlignContent="center"
        hAlignContent="right"
      >
        {workspaceEnterCTA(group)}
      </Flex>
    );
  };

  return (
    <EntityTable
      id="groups-table"
      entities={groups}
      primaryColumn={workspaceNameColumn}
      actionMenu={({ entity }) => (
        <>
          {workspaceEnterColumn(entity as Group)}
          {(authenticatedUserEntity.isOrganizationAdmin ||
            authenticatedUserEntity.id === entity.author?.id) && (
            <GroupActionMenu
              group={entity as Group}
              actions={['delete', 'manage_members']}
            />
          )}
        </>
      )}
      withCheckbox={authenticatedUserEntity.isOrganizationAdmin}
      searchParams={{}}
      setParams={() => {}}
    />
  );
};

export default withAuthenticatedUserEntity(GroupTable);
