/**
 * Labstep
 *
 * @module components/ResourceItem/Breadcrumb
 * @desc Breadcrumb for ResourceItem
 */

import React from 'react';
import Breadcrumb from 'labstep-web/core/Breadcrumb';
import { IBreadcrumbLink } from 'labstep-web/core/Breadcrumb/types';
import { IResourceItemBreadcrumbProps } from './types';

export const ResourceItemBreadcrumb: React.FC<
  IResourceItemBreadcrumbProps
> = ({ resourceItem }) => {
  const resourceLink: IBreadcrumbLink = {
    name: resourceItem.resource.displayName,
    icon: 'flask',
    route: {
      to: 'resource_show',
      params: { id: resourceItem.resource.id },
    },
  };

  let links: IBreadcrumbLink[] = [resourceLink];

  if (resourceItem.resource.template) {
    const resourceTemplateLink: IBreadcrumbLink = {
      icon: 'box',
      name: resourceItem.resource.template.displayName,
      route: {
        to: 'resource_index',
        query: { template_id: resourceItem.resource.template.id },
      },
    };
    links = [resourceTemplateLink, resourceLink];
  }

  return <Breadcrumb links={links} />;
};

export default ResourceItemBreadcrumb;
