/**
 * Labstep
 *
 * @module core/Action/Component
 * @desc A generic component for action elements
 */

import ActionIcon from 'labstep-web/core/Action/Icon';
import ActionText from 'labstep-web/core/Action/Text';
import Button from 'labstep-web/core/Button';
import Checkbox from 'labstep-web/core/Checkbox';
import Dropdown from 'labstep-web/core/Dropdown';
import Icon from 'labstep-web/core/Icon';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import React from 'react';
import ActionComponentLabel from './Label';
import { IActionComponentProps } from './types';

const DEFAULT_DATA_TEST_ID = 'action-component';

export const ActionComponent: React.FC<IActionComponentProps> = ({
  icon,
  cornerIcon,
  text,
  status,
  onClick,
  disabled,
  modalConfirmationProps,
  dataTestId,
  ...props
}) => {
  const sharedProps = {
    'data-testid': dataTestId || DEFAULT_DATA_TEST_ID,
    disabled: !!(status && status.isFetching) || disabled,
    [props.type === 'checkbox' ? 'onChange' : 'onClick']: onClick,
  };

  // If the action is a button, we only show the icon if the showIcon prop is true
  const iconIfShow =
    props.type !== 'button' || props.showIcon ? icon : undefined;
  const sharedPropsWithLabel = {
    ...sharedProps,
    children: (
      <ActionComponentLabel
        icon={iconIfShow}
        cornerIcon={cornerIcon}
        text={text}
      />
    ),
  };

  switch (props.type) {
    case 'button':
      return (
        <Button
          {...sharedPropsWithLabel}
          color="blue"
          {...props.elementProps}
        />
      );

    case 'icon':
      return (
        <ActionIcon
          {...sharedProps}
          name={icon}
          {...props.elementProps}
        />
      );

    case 'checkbox':
      return (
        <Checkbox {...sharedProps} toggle {...props.elementProps} />
      );

    case 'option':
      return (
        <Dropdown.Item
          {...sharedProps}
          {...props.elementProps}
          data-public
        >
          {iconIfShow && (
            <Icon name={iconIfShow} cornerIcon={cornerIcon} />
          )}
          {text}
        </Dropdown.Item>
      );

    case 'text':
      return (
        <ActionText
          {...sharedPropsWithLabel}
          underline={!icon}
          {...props.elementProps}
          data-public
        />
      );

    case 'children':
      return props.children({ onClick, status });
    default:
      return null;
  }
};

ActionComponent.defaultProps = {
  type: 'option',
};

export const ActionComponentModalContainer: React.FC<
  IActionComponentProps
> = ({ modalConfirmationProps, onClick, ...rest }) =>
  modalConfirmationProps ? (
    <ModalConfirmation
      status={rest.status}
      onConfirm={({ toggleModal }) =>
        onClick?.({ onSuccess: toggleModal })
      }
      {...modalConfirmationProps}
    >
      {({ toggleModal }) => (
        <ActionComponent onClick={toggleModal} {...rest} />
      )}
    </ModalConfirmation>
  ) : (
    <ActionComponent onClick={onClick} {...rest} />
  );

export default ActionComponentModalContainer;
