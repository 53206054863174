import ActionComponentLabel from 'labstep-web/core/Action/Component/Label';
import { Signature } from 'labstep-web/models/signature.model';
import React from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import SignatureCard from '../../../Card';
import styles from '../styles.module.scss';
import { SignatureListTimelineExistingSignaturesProps } from './types';
import { getSignaturesChronologically } from './utils';

export const SignatureListTimelineExistingSignatures: React.FC<
  SignatureListTimelineExistingSignaturesProps
> = ({ experimentWorkflow }) => {
  const sortedSignatures = getSignaturesChronologically(
    experimentWorkflow,
  );
  return (
    <>
      {sortedSignatures.map((signature: Signature, index) => {
        const { entity_state_round, entity_state } = signature;

        // Determine if a header should be inserted before this signature
        const showHeader =
          index === sortedSignatures.length - 1 || // Always show a header before the first signature
          sortedSignatures[index + 1].entity_state_round !==
            entity_state_round ||
          sortedSignatures[index + 1].entity_state.id !==
            entity_state.id;

        return (
          <React.Fragment key={signature.id}>
            <div className={styles.box}>
              <SignatureCard
                key={signature.id}
                signature={signature}
                entityUserRole={signature.entityUserRole!}
                experimentWorkflow={experimentWorkflow}
              />
            </div>
            {showHeader && (
              <div className={styles.stateBox}>
                <ActionComponentLabel
                  iconSize="large"
                  icon={signature.entity_state.icon as SemanticICONS}
                  text={signature.entity_state!.name}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
