/**
 * Labstep
 *
 * @module components/Group/Card/About
 * @desc About card for group
 */

import { GroupActionShare } from 'labstep-web/components/Group/Action/Share';
import GroupFormShowEditDescription from 'labstep-web/components/Group/Form/ShowEdit/Description';
import { fieldName } from 'labstep-web/components/Group/Form/fields';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import UserList from 'labstep-web/components/User/List/Cap';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Flex from 'labstep-web/core/Flex';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Header from 'labstep-web/core/Header';
import { Size } from 'labstep-web/core/Initials/types';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import { GroupActionManageMembers } from '../../Action/ManageMembers';
import styles from './styles.module.scss';
import { IGroupCardAboutProps, IMembersProps } from './types';

export const Members: React.FC<IMembersProps> = ({
  group,
  manageMembers = true,
}) => (
  <Flex shrink={0} vAlignContent="bottom">
    <Flex wrap vAlignContent="center" shrink={0}>
      <Header size="mini" className={styles.membersHeader}>
        Members
      </Header>
      <Flex
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex>
          <UserList params={{ group_id: group.id }} />
          {manageMembers && (
            <Popup
              trigger={
                <GroupActionManageMembers group={group} iconOnly />
              }
              content="Manage Members"
              size="mini"
            />
          )}
        </Flex>
        <GroupActionShare
          group={group}
          actionComponentProps={{
            type: 'button',
          }}
        />
      </Flex>
    </Flex>
  </Flex>
);

export const GroupCardAbout: React.FC<IGroupCardAboutProps> = ({
  group,
  authenticatedUserEntity,
}) => (
  <div className={styles.container}>
    <div>
      <ProfilePicture
        bordered
        entity={group}
        size={Size.small}
        circular={false}
        rounded
        uploadable
      />
    </div>
    <Flex column grow spacing="gap" className={styles.info}>
      <div className={styles.name}>
        <ShowEditText
          entityName={group.entityName}
          entity={group}
          field={{ ...fieldName, header: true }}
        >
          <Header isDataPublic={false} size="small" noMargin>
            {group.name}
          </Header>
        </ShowEditText>
        <GroupFormShowEditDescription group={group} />
      </div>
      {(group.logged_user_user_group ||
        authenticatedUserEntity.isOrganizationAdmin) && (
        <Members
          group={group}
          manageMembers={
            authenticatedUserEntity.isOrganizationAdmin ||
            group.logged_user_user_group?.type === 'owner'
          }
        />
      )}
    </Flex>
  </div>
);

export default withAuthenticatedUserEntity(GroupCardAbout);
