/**
 * Labstep
 *
 * @module components/Organization/Header
 * @desc Organization Header component
 */

import OrganizationLogo from 'labstep-web/components/Organization/Logo';
import Header from 'labstep-web/core/Header';
import React from 'react';
import styles from './styles.module.scss';
import { IOrganizationHeaderProps } from './types';

const OrganizationHeader: React.FC<IOrganizationHeaderProps> = ({
  organization,
  size,
}) => (
  <div className={styles.container}>
    <OrganizationLogo organization={organization} size={size} />
    <Header isDataPublic={false}>{organization.name}</Header>
  </div>
);

export default OrganizationHeader;
