/**
 * Labstep
 *
 * @module models/experiment
 * @desc Typescript export class for Experiment
 */

import { Type } from 'class-transformer';
import { getStatesBeforeCurrentState } from 'labstep-web/components/Signature/Form/utils';
import { ICONS } from 'labstep-web/constants/icons';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflowLink } from 'labstep-web/models/experiment-workflow-link.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Group } from 'labstep-web/models/group.model';
import { Log } from 'labstep-web/models/log.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { Signature } from 'labstep-web/models/signature.model';
import { Tag } from 'labstep-web/models/tag.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { IPermissions, PermissionActions } from 'labstep-web/typings';
import { EntityStateWorkflow } from './entity-state-workflow.model';
import { EntityState } from './entity-state.model';
import { EntityUserRoleRequirement } from './entity-user-role-requirement.model';
import { SignatureRequest } from './signature-request.model';

interface SignatureTypeInfo {
  icon: IIconProps['name'];
  text: string;
  params: {
    signature_request_user_id?: 'false' | 'true';
    has_signature?: 'false' | 'true';
    has_signature_request?: 'false' | 'true';
  };
}

type SignatureTypeName = 'unsigned' | 'signed' | 'request_pending';

export const SignedTypes: Record<
  SignatureTypeName,
  SignatureTypeInfo
> = {
  unsigned: {
    icon: 'edit outline',
    text: 'Unsigned',
    params: {
      signature_request_user_id: undefined,
      has_signature: 'false',
      has_signature_request: 'false',
    },
  },
  request_pending: {
    icon: 'clock',
    text: 'Request Pending',
    params: {
      signature_request_user_id: undefined,
      has_signature: undefined,
      has_signature_request: 'true',
    },
  },
  signed: {
    icon: 'edit',
    text: 'Signed',
    params: {
      signature_request_user_id: undefined,
      has_signature: 'true',
      has_signature_request: 'false',
    },
  },
};

type StatusTypeName = 'unstarted' | 'started' | 'completed';

interface StatusTypeInfo {
  icon: IIconProps['name'];
  text: string;
  color?: IIconProps['color'];
  params: {
    is_started: 'false' | 'true';
    is_ended: 'false' | 'true';
  };
}

export const ExperimentWorkflowStatusKeys = {
  unstarted: 'unstarted',
  started: 'started',
  completed: 'completed',
};

type IExperimentWorkflowStatusValues = {
  [key in keyof typeof ExperimentWorkflowStatusKeys]: string;
};

export const ExperimentWorkflowStatusValues: IExperimentWorkflowStatusValues =
  {
    unstarted: 'Unstarted',
    started: 'Started',
    completed: 'Completed',
  };

export const StatusTypes: Record<StatusTypeName, StatusTypeInfo> = {
  unstarted: {
    icon: ICONS.experiment_workflow.info.unstarted,
    text: ExperimentWorkflowStatusValues.unstarted,
    params: {
      is_started: 'false',
      is_ended: 'false',
    },
  },
  started: {
    icon: ICONS.experiment_workflow.info.started,
    text: ExperimentWorkflowStatusValues.started,
    color: 'yellow',
    params: {
      is_started: 'true',
      is_ended: 'false',
    },
  },
  completed: {
    icon: ICONS.experiment_workflow.info.completed,
    color: 'green',
    text: ExperimentWorkflowStatusValues.completed,
    params: { is_started: 'true', is_ended: 'true' },
  },
};

export class ExperimentWorkflow extends Entity {
  static readonly entityName = 'experiment_workflow';

  get entityName(): typeof ExperimentWorkflow.entityName {
    return ExperimentWorkflow.entityName;
  }

  constructor(data: Partial<ExperimentWorkflow> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  protected name!: string;

  custom_identifier!: string;

  started_at!: string;

  ended_at!: string;

  start_planned_at!: string;

  end_planned_at!: string;

  permissions!: IPermissions;

  allowed_actions!: PermissionActions[];

  allowed_actions_lock!: PermissionActions[];

  description!: string;

  comment_count!: number;

  experiment_count!: number;

  metadata_count!: number;

  protocol_device_count!: number;

  protocol_value_count!: number;

  forward_link_count!: number;

  back_link_count!: number;

  signature_count!: number;

  locked_at?: string;

  metadata_thread_ids!: number[];

  experiment_ids!: number[];

  is_template!: boolean;

  entity_users_count!: number;

  entity_state_id!: number | string;

  entity_state_round!: number;

  @Type(() => Group)
  owner!: Group;

  @Type(() => User)
  author!: User;

  @Type(() => Thread)
  thread!: Thread;

  @Type(() => Experiment)
  root_experiment!: Experiment;

  @Type(() => MetadataThread)
  metadata_thread!: MetadataThread;

  @Type(() => Experiment)
  experiments!: Experiment[];

  @Type(() => Signature)
  signatures!: Signature[];

  @Type(() => SignatureRequest)
  signature_requests!: SignatureRequest[];

  @Type(() => Tag)
  tags!: Tag[];

  @Type(() => ShareLink)
  share_link!: ShareLink;

  @Type(() => Log)
  locked_log!: Log;

  @Type(() => EntityUser)
  entity_users_preview!: EntityUser[];

  @Type(() => PermaLink)
  perma_link?: PermaLink;

  @Type(() => ExperimentWorkflow)
  forward_links!: ExperimentWorkflowLink[];

  @Type(() => ExperimentWorkflow)
  back_links!: ExperimentWorkflowLink[];

  @Type(() => EntityStateWorkflow)
  entity_state_workflow!: EntityStateWorkflow | null;

  @Type(() => EntityState)
  entity_state!: EntityState;

  get nameNoCustomIdentifier(): string {
    return this.name;
  }

  get displayName(): string {
    return this.custom_identifier
      ? `${this.custom_identifier} - ${this.name}`
      : this.name;
  }

  get isSigned(): boolean {
    return this.signature_count > 0;
  }

  get totalLinkCount(): number {
    return this.forward_link_count + this.back_link_count;
  }

  get statusType(): StatusTypeName {
    if (!this.started_at) {
      return 'unstarted';
    }
    if (!this.ended_at) {
      return 'started';
    }
    return 'completed';
  }

  get activeStart(): string {
    return this.started_at || this.start_planned_at;
  }

  get activeEnd(): string {
    return this.ended_at || this.end_planned_at;
  }

  get incompleteExperimentsCount(): number {
    return this.experiments.reduce((count, e) => {
      const add = e.ended_at ? 0 : 1;
      return count + add;
    }, 0);
  }

  get nextEntityState(): EntityState | null {
    const entityStates = this.entity_state_workflow?.entity_states;
    if (!entityStates) {
      return null;
    }
    const index = entityStates.findIndex(
      (state) => state.id === this.entity_state.id,
    );
    if (index === -1 || index === entityStates.length - 1) {
      return null;
    }
    return entityStates[index + 1];
  }

  get hasNextEntityState(): boolean {
    return this.nextEntityState !== null;
  }

  get entityUserRoleRequirements(): EntityUserRoleRequirement[] {
    return (
      this.entity_state_workflow?._entity_states.reduce<
        EntityUserRoleRequirement[]
      >((entityUserRoleRequirements, entityState) => {
        return entityUserRoleRequirements.concat(
          entityState.entity_user_role_requirements,
        );
      }, []) || []
    );
  }

  hasMetSignatureRequirementsForEntityState(
    entityState: EntityState,
    entityStateRound: number,
  ): boolean {
    return entityState.entity_user_role_requirements.reduce(
      (result, entityUserRoleRequirement) => {
        if (!entityUserRoleRequirement.signature_requirement) {
          return result;
        }
        const signatures = this.signatures
          .filter((signature) => !signature.revoked_at)
          .filter(
            (signature) =>
              signature.signature_requirement_id ===
              entityUserRoleRequirement.signature_requirement?.id,
          );
        return (
          result &&
          signatures.filter(
            (signature) =>
              signature.entity_state_round === entityStateRound,
          ).length >= entityUserRoleRequirement.number_required
        );
      },
      true,
    );
  }

  public getIsForwardEntityState(id: number | string): boolean {
    const previousStates = getStatesBeforeCurrentState(this);
    return (
      previousStates.find((state) => state.id === id) === undefined &&
      id !== this.entity_state.id
    );
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
    isTemplate?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
      isTemplate,
    );
  }

  public getSignaturesAtRound(round: number) {
    return this.signatures.filter(
      (signature) => signature.entity_state_round === round,
    );
  }

  public getNumberOfSignaturesRequiredLeft(
    entityState: EntityState,
  ): number {
    const signatures = this.getSignaturesAtRound(
      this.entity_state_round,
    ).filter((signature) => !signature.revoked_at);

    let count = 0;
    const signatureRequirements = entityState.signature_requirements;
    if (signatureRequirements.length === 0) {
      return count;
    }

    signatureRequirements.forEach((signatureRequirement) => {
      const entityUserRoleRequirement =
        entityState.entity_user_role_requirements.find(
          (requirement) =>
            requirement.signature_requirement?.id ===
            signatureRequirement.id,
        );

      if (entityUserRoleRequirement) {
        const numberRequired =
          entityUserRoleRequirement!.number_required;
        const signaturesForRequirement = signatures.filter(
          (signature) =>
            signature.entity_user_role_requirement_id ===
            entityUserRoleRequirement.id,
        );
        count += numberRequired - signaturesForRequirement.length;
      }
    });
    return count;
  }
}
