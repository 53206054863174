import SignatureCardToBeAssigned from 'labstep-web/components/Signature/Card/ToBeAssigned';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import React from 'react';
import { ISignatureListEntityStateCardsToBeAssignedProps } from './types';

export const SignatureListEntityStateCardsToBeAssigned: React.FC<
  ISignatureListEntityStateCardsToBeAssignedProps
> = ({
  authenticatedUserEntity,
  entityState,
  experimentWorkflow,
  entityUsers,
  round,
}) => {
  const cards: JSX.Element[] = [];
  const unassignedSignatures: JSX.Element[] = [];

  entityState.entity_user_role_requirements.forEach(
    (entityUserRoleRequirement) => {
      const entityUsersForEntityUserRoleRequirement =
        entityUsers.filter(
          (entityUser) =>
            entityUser.entity_user_role?.id ===
            entityUserRoleRequirement.entity_user_role.id,
        );
      entityUsersForEntityUserRoleRequirement.forEach(
        (entityUser) => {
          const signatures = experimentWorkflow.signatures
            .filter((s) => !s.revoked_at)
            .filter(
              (s) =>
                s.entity_user_role_requirement_id ===
                entityUserRoleRequirement.id,
            );
          if (
            signatures.find(
              (signature) =>
                signature.author.id === entityUser.user.id &&
                signature.entity_state_round === round,
            )
          ) {
            return;
          }
          if (
            !entityUserRoleRequirement.signature_requirement &&
            entityUser.user.id !== authenticatedUserEntity.id
          ) {
            cards.push(<p>No signatures have been received</p>);
          } else {
            cards.push(
              <SignatureCardToBeAssigned
                experimentWorkflow={experimentWorkflow}
                key={entityUser.id}
                assignee={entityUser.user}
                entityUser={entityUser}
                entityState={entityState}
                entityUserRole={
                  entityUserRoleRequirement.entity_user_role
                }
              />,
            );
          }
        },
      );

      for (
        let i = 0;
        i <
        entityUserRoleRequirement.number_required -
          entityUsersForEntityUserRoleRequirement.length;
        i += 1
      ) {
        unassignedSignatures.push(
          <SignatureCardToBeAssigned
            experimentWorkflow={experimentWorkflow}
            key={i}
            entityUserRole={
              entityUserRoleRequirement.entity_user_role
            }
            entityState={entityState}
          />,
        );
      }
    },
  );

  return <>{cards.concat(unassignedSignatures)}</>;
};

export default withAuthenticatedUserEntity(
  SignatureListEntityStateCardsToBeAssigned,
);
