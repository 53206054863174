/**
 * Labstep
 *
 * @module core/Timeline/Right
 * @desc ExperimentWorkflow Timeline Right
 */

import classnames from 'classnames';
import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import Card from 'labstep-web/core/Card/Card';
import Header from 'labstep-web/core/Header';
import { Size } from 'labstep-web/core/Initials/types';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import {
  minutesDifference,
  timeOnly,
} from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { IDeviceBookingTimeGridEventProps } from './types';

const MINUTE_CUTOFFS = [45, 90, 120];

export const DeviceBookingTimeGridEvent: React.FC<
  IDeviceBookingTimeGridEventProps
> = ({ deviceBooking, setActiveEditModal, disabled }) => {
  const duration = minutesDifference(
    deviceBooking.ended_at,
    deviceBooking.started_at,
  );
  const isShortBooking = duration <= MINUTE_CUTOFFS[0];
  const isMediumBooking = duration > MINUTE_CUTOFFS[1];
  const isLongBooking = duration > MINUTE_CUTOFFS[2];

  return (
    <Card
      className={classnames(styles.eventCard, {
        [styles.disabled]: disabled,
        [styles.shortBooking]: isShortBooking,
      })}
      onClick={() =>
        setActiveEditModal({
          entityName: DeviceBooking.entityName,
          id: deviceBooking.id,
        })
      }
    >
      <div className={styles.title}>
        {!isShortBooking && (
          <ProfilePictureImage
            size={Size.mini}
            entity={deviceBooking.author}
          />
        )}
        <Header isDataPublic={false} size="mini" noMargin>
          {deviceBooking.author.name}
        </Header>
      </div>
      {isMediumBooking && (
        <div>
          <span>
            {timeOnly(deviceBooking.started_at)} -{' '}
            {timeOnly(deviceBooking.ended_at)}
          </span>
        </div>
      )}
      {isLongBooking && (
        <div>
          <span>{deviceBooking.description}</span>
        </div>
      )}
    </Card>
  );
};

export default withActiveEditModal(DeviceBookingTimeGridEvent);
