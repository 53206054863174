/**
 * Labstep
 *
 * @module models/signature
 * @desc Typescript export class for ShareLink
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { EntityState } from './entity-state.model';
import { EntityUserRole } from './entity-user-role.model';

export class Signature extends Entity {
  static readonly entityName = 'signature';

  get entityName(): string {
    return Signature.entityName;
  }

  constructor(data: Partial<Signature> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  signature_requirement_id!: number;

  entity_user_role_requirement_id!: number;

  reject_entity_state_id!: string;

  is_reject!: boolean;

  entity_state_id!: number | string;

  @Type(() => ExperimentWorkflow)
  experimentWorkflow!: ExperimentWorkflow;

  @Type(() => User)
  author!: User;

  @Type(() => EntityState)
  entity_state!: EntityState;

  entity_state_round!: number;

  statement!: string;

  revoked_at!: string;

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }

  get entityUserRole(): EntityUserRole | null {
    if (this.entity_state) {
      const entityUserRoleRequirement =
        this.entity_state.entity_user_role_requirements.find(
          (e) => e.id === this.entity_user_role_requirement_id,
        );

      if (entityUserRoleRequirement) {
        return entityUserRoleRequirement.entity_user_role;
      }
    }
    return null;
  }
}
