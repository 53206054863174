/**
 * Labstep
 *
 * @module models/group
 * @desc Typescript export class for Group
 */

import { Type } from 'class-transformer';
import {
  PERMISSION_ROLE_EDITOR,
  PERMISSION_ROLE_OWNER,
  PERMISSION_ROLE_VIEWER,
} from 'labstep-web/constants/roles';
import { CustomIdentifierSet } from 'labstep-web/models/custom-identifier-set.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { Organization } from 'labstep-web/models/organization.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import { PermissionType } from 'labstep-web/models/permission.model';
import { Profile } from 'labstep-web/models/profile.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { Thread } from 'labstep-web/models/thread.model';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { PermissionActions } from 'labstep-web/typings';
import { EntityStateWorkflow } from './entity-state-workflow.model';
import { EntityUserRoleRequirement } from './entity-user-role-requirement.model';
import { EntityUserRole } from './entity-user-role.model';
import { OrderRequest } from './order-request.model';

export class Group extends Entity {
  public static readonly entityName = 'group';

  public get entityName(): typeof Group.entityName {
    return Group.entityName;
  }

  public constructor(data: Partial<Group>) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public default_user_group_type!: PermissionType;

  public name!: string;

  public roles!: string[];

  public city!: string;

  public country!: string;

  public user_count!: number;

  public is_home!: boolean;

  public description!: string;

  public is_invite_only!: boolean;

  public is_auto_member!: boolean;

  public default_signature_statement?: string;

  public default_signature_request_message?: string;

  public is_signature_two_factor_required!: boolean;

  public is_completion_checklist_enabled!: boolean;

  public is_external_user_allowed!: boolean;

  public default_order_request_template_id!: number;

  public allowed_actions!: PermissionActions[];

  public get canLeave(): boolean {
    return (
      this.logged_user_user_group &&
      this.logged_user_user_group.type !== 'owner' &&
      !this.is_home
    );
  }

  public get canChangeCustomIdentifierSet(): boolean {
    return (
      this.logged_user_user_group &&
      this.logged_user_user_group.type === 'owner'
    );
  }

  public get canCreate(): boolean {
    return (
      this.logged_user_user_group === null ||
      this.logged_user_user_group.type !== 'view'
    );
  }

  public get canInvite(): boolean {
    return this.organization ? this.is_external_user_allowed : true;
  }

  public get canEnableSignatureTwoFactorRequirement(): boolean {
    return !!(
      this.organization && this.organization.hasFeatureEnabled('2fa')
    );
  }

  public get defaultPermissionRoleName(): string {
    if (this.default_user_group_permission_role) {
      return this.default_user_group_permission_role.name;
    }
    if (this.default_user_group_type === PermissionType.view) {
      return PERMISSION_ROLE_VIEWER.name;
    }
    if (this.default_user_group_type === PermissionType.owner) {
      return PERMISSION_ROLE_OWNER.name;
    }
    return PERMISSION_ROLE_EDITOR.name;
  }

  @Type(() => User)
  public author!: User;

  @Type(() => ExperimentWorkflow)
  public default_experiment_workflow_template!: ExperimentWorkflow;

  @Type(() => OrderRequest)
  public default_order_request_template!: OrderRequest;

  @Type(() => EntityStateWorkflow)
  public default_entity_state_workflow!: EntityStateWorkflow;

  @Type(() => Thread)
  public thread!: Thread;

  @Type(() => Profile)
  public profile!: Profile;

  @Type(() => UserGroup)
  public user_groups!: UserGroup[];

  @Type(() => UserGroup)
  public logged_user_user_group!: UserGroup;

  @Type(() => Organization)
  public organization!: Organization;

  @Type(() => CustomIdentifierSet)
  public experiment_workflow_custom_identifier_set!: CustomIdentifierSet;

  @Type(() => CustomIdentifierSet)
  public custom_identifier_sets!: CustomIdentifierSet[];

  @Type(() => PermissionRole)
  public default_user_group_permission_role!: PermissionRole;

  @Type(() => EntityUserRole)
  public _entity_user_roles!: EntityUserRole[];

  public get entity_user_roles(): EntityUserRole[] {
    return this._entity_user_roles.filter((e) => !e.deleted_at);
  }

  public set entity_user_roles(entityUserRoles: EntityUserRole[]) {
    this._entity_user_roles = entityUserRoles;
  }

  @Type(() => EntityUserRoleRequirement)
  public entity_user_role_requirements!: EntityUserRoleRequirement[];

  public purchase_order_template_id!: PurchaseOrder['id'];

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }

  public get experimentWorkflowCustomIdentifierSet():
    | CustomIdentifierSet
    | undefined {
    return this.custom_identifier_sets.find(
      (customIdentifierSet) =>
        !customIdentifierSet.deleted_at &&
        customIdentifierSet.parent_class ===
          ExperimentWorkflow.entityName,
    );
  }

  getUniqueCustomIdentifierSet(
    parentClass: string,
  ): CustomIdentifierSet | undefined {
    return this.custom_identifier_sets.find(
      (customIdentifierSet) =>
        !customIdentifierSet.deleted_at &&
        customIdentifierSet.parent_class === parentClass,
    );
  }
}
