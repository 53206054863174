/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Commands/Menu/Item
 * @desc A menu item for elements
 */

import Card from 'labstep-web/core/Card/Card';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import Image from 'labstep-web/core/Image';
import Menu from 'labstep-web/core/Menu';
import React from 'react';
import MenuCommandsMenuItemLabel from './Label';
import styles from './styles.module.scss';
import { IMenuCommandsMenuItemProps } from './types';

const MenuCommandsMenuItem: React.FC<IMenuCommandsMenuItemProps> = ({
  onClick,
  header,
  icon,
  image,
  description,
  active,
  disabled,
  label,
}) => (
  <Menu.Item
    data-testid="menu_item"
    active={active}
    disabled={disabled}
    onClick={onClick}
    data-public
  >
    <Flex vAlignContent="center">
      <Card className={styles.iconCard}>
        {icon && <Icon name={icon} className={styles.icon} />}
        {image && (
          <Image src={image} className={styles.icon} data-public />
        )}
      </Card>
      <div className={styles.textContainer}>
        <Header className={styles.header} size="mini" noMargin>
          {header}
          {label && <MenuCommandsMenuItemLabel label={label} />}
        </Header>
        <p data-public>{description}</p>
      </div>
    </Flex>
  </Menu.Item>
);

export default MenuCommandsMenuItem;
