/**
 * Labstep
 *
 * @module prosemirror
 * @desc ProseMirror main entry point
 */

import classnames from 'classnames';
import MenuMain from 'labstep-web/prosemirror/components/Menu/Main';
import { getNodeViews } from 'labstep-web/prosemirror/components/NodeView';
import {
  ReactNodeViewPortals,
  useReactNodeViewPortals,
} from 'labstep-web/prosemirror/components/ReactNodeViewPortals';
import { computeCursorPosition } from 'labstep-web/prosemirror/cursor';
import { reactPropsKey } from 'labstep-web/prosemirror/extensions/external-comm';
import { stepsPluginKey } from 'labstep-web/prosemirror/extensions/steps';
import { getInitialState } from 'labstep-web/prosemirror/state/initial-state';
import {
  handlePaste,
  preventKeyPropagation,
  sanitizeInitialState,
} from 'labstep-web/prosemirror/utils';
import { handleDrop } from 'labstep-web/prosemirror/utils/dnd';
import bugsnagService from 'labstep-web/services/bugsnag.service';
import { LogRocketService } from 'labstep-web/services/logrocket.service';
import { ProsemirrorService } from 'labstep-web/services/prosemirror.service';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useStepsOrderContext } from './context';
import { DocType } from './context/types';
import { commentPluginKey } from './extensions/comment';
import { editablePluginKey } from './extensions/editable/plugin';
import { commandsPluginKey } from './extensions/slash/plugin';
import styles from './styles.module.scss';
import { IProseMirrorTypes } from './types';

export const INNER_EDITOR_CONTAINER_ID = 'inner-editor-scrollable';

export const ProseMirrorEditor: React.FC<IProseMirrorTypes> = ({
  onChange,
  initialState,
  editable = true,
  entity,
  experimentWorkflow,
  protocolCollection,
  toolbarRight,
  topMenuClassName,
  premiumFeatures,
  portals,
  proseMirrorKey,
}) => {
  const [view, setView] = useState<EditorView>(null);
  const [state, setState] = useState<EditorState>(null);

  const { createPortal } = useReactNodeViewPortals();
  const { setStepsOrder } = useStepsOrderContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCreatePortal = useCallback(createPortal, []);

  const editorViewRef = useRef(null);
  const innerEditorContainerRef = useRef(null);

  const createEditorView = useCallback((editorViewDOM) => {
    const editorView = new EditorView(editorViewDOM, {
      state: getInitialState(sanitizeInitialState(initialState), {
        entity,
        experimentWorkflow,
        handleCreatePortal,
        premiumFeatures,
      }),
      handleDrop,
      handlePaste,
      nodeViews: getNodeViews(entity, handleCreatePortal),
      editable: () => editable,
    });
    setView(editorView);
    setState(editorView.state);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (view) {
      view.setProps({
        dispatchTransaction(transaction) {
          const { selection, storedMarks, steps, doc } = transaction;

          LogRocketService.log({
            selection: selection.toJSON(),
            steps: JSON.stringify(steps),
            storedMarks,
            doc: doc.toJSON(),
            transaction,
          });

          const newState = view.state.apply(transaction);
          view.updateState(newState);

          if (transaction.getMeta(commandsPluginKey)) {
            view.focus();
          }

          const decorations =
            ProsemirrorService.transformDecorationState(
              view.state.plugins
                .find(
                  (plugin) => plugin.spec.key === commentPluginKey,
                )
                .getState(view.state).decos.children,
            );

          if (
            transaction.docChanged ||
            transaction.getMeta(commentPluginKey)
          ) {
            onChange(view.state.doc.toJSON(), {
              comments: decorations,
            });
          }

          setState(view.state);
          setStepsOrder(newState.doc.toJSON() as DocType);
        },
      });
      view.focus();
      // Needed to initialise decorations
      view.dispatch(
        view.state.tr.setMeta(stepsPluginKey, {
          editable,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    if (view) {
      view.setProps({ editable: () => editable });
      view.dispatch(
        view.state.tr.setMeta(stepsPluginKey, {
          editable,
        }),
      );
      view.dispatch(
        view.state.tr.setMeta(editablePluginKey, editable),
      );
      view.focus();
    }
  }, [editable, view]);

  useEffect(() => {
    if (!view || !editable) {
      return;
    }
    // every time entity changes
    const tr = view.state.tr.setMeta(reactPropsKey, {
      entity,
      experimentWorkflow,
    });
    view.dispatch(tr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    if (!view) {
      const editorViewDOM = editorViewRef.current;
      if (editorViewDOM) {
        createEditorView(editorViewDOM);
      }
    }
  }, [createEditorView, view]);

  useEffect(() => {
    return () => {
      if (view) {
        // This is required otherwise when navigating away from the
        // editor another transaction is sent causing the
        // step content to disappear and not saved
        view.destroy();
      }
    };
  }, [view]);

  useEffect(() => {
    const handlePreventKeyPropagation = (e: KeyboardEvent) =>
      preventKeyPropagation(e, view);
    if (view) {
      window.addEventListener('keydown', handlePreventKeyPropagation);
    }
    return () =>
      window.removeEventListener(
        'keydown',
        handlePreventKeyPropagation,
      );
  }, [view]);

  const cursorPosition = computeCursorPosition(state, view);

  useEffect(() => {
    if (view) {
      const { schema } = view.state;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let newContent: any;
      try {
        newContent = schema.nodeFromJSON(
          sanitizeInitialState(entity.state),
        );
      } catch (e) {
        bugsnagService.notify(
          new Error('Error parsing JSON from entity state'),
        );
        return;
      }

      const { tr } = view.state;

      // Replace the entire document content
      tr.replaceWith(0, view.state.doc.content.size, newContent);
      // Disable history for this transaction
      tr.setMeta('addToHistory', false);

      // Apply the transaction to create a new state
      const newState = view.state.apply(tr);

      // Update the editor view with the new state
      view.updateState(newState);
    }
  }, [proseMirrorKey]);

  return (
    <div className={classnames(styles.container, styles.editable)}>
      <div
        className={classnames(styles.topMenu, topMenuClassName)}
        style={!editable ? { display: 'none' } : undefined}
      >
        <div id={`toolbar-main-${entity.id}`} />
        <div>{toolbarRight}</div>
      </div>
      <div className={styles.editorContainer}>
        <div
          className={styles.innerEditorContainer}
          id={INNER_EDITOR_CONTAINER_ID}
          ref={innerEditorContainerRef}
        >
          {state && view && (
            <MenuMain
              entity={entity}
              experimentWorkflow={experimentWorkflow}
              protocolCollection={protocolCollection}
              state={state}
              dispatch={view.dispatch}
              view={view}
              cursorPosition={cursorPosition}
              editable={editable}
            />
          )}
          <div
            className={classnames(
              { [styles.editor]: editable },
              styles.prosemirrorStyles,
            )}
          >
            <div id="prose-mirror-editor" ref={editorViewRef} />
            <ReactNodeViewPortals portals={portals} />
          </div>
        </div>
      </div>
    </div>
  );
};
