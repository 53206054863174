/**
 * Labstep
 *
 * @module core/Sortable
 * @desc Component for sorting lists
 */

import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import React from 'react';
import {
  IItemProps,
  ISortableContainerProps,
  ISortableContainerState,
} from './types';
import {
  arrayMoveImmutable as arrayMove,
  getPosition,
} from './utils';

class SortableContainer extends React.Component<
  ISortableContainerProps,
  ISortableContainerState
> {
  public static defaultProps = {
    sort: 'asc',
  };

  public constructor(props: ISortableContainerProps) {
    super(props);
    this.state = { items: this.orderItems() };
    this.onSortEnd = this.onSortEnd.bind(this);
    this.orderItems = this.orderItems.bind(this);
  }

  public componentDidUpdate(
    prevProps: ISortableContainerProps,
  ): void {
    if (!isEqual(prevProps.items, this.props.items)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        items: this.orderItems(),
      });
    }
  }

  public onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    if (oldIndex === newIndex) {
      return;
    }

    const position = getPosition(
      this.state.items,
      oldIndex,
      newIndex,
      this.props.sort,
    );

    // eslint-disable-next-line react/no-access-state-in-setstate
    const items = arrayMove(this.state.items, oldIndex, newIndex);

    const { id } = this.state.items[oldIndex];

    this.props.onSortEnd({
      id,
      position,
      items,
    });

    this.setState({
      items,
    });
  };

  public orderItems(): IItemProps[] {
    const { items, sort } = this.props;
    return orderBy(items, ['position'], sort ? [sort] : undefined);
  }

  public render(): ReturnType<React.FC> {
    const { items } = this.state;
    const { render } = this.props;

    return render({ items, onSortEnd: this.onSortEnd });
  }
}

export default SortableContainer;
