/**
 * Labstep
 *
 * @module components/ResourceLocation/MapModal/Content/Right/List
 * @desc A component to load a list of entities for right content of map modal
 */

import classnames from 'classnames';
import EntitySearch from 'labstep-web/components/Entity/Search';
import List from 'labstep-web/core/List';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import styles from './styles.module.scss';
import {
  IResourceLocationMapModalContentRightListMapProps,
  InfoInterface,
} from './types';

const getParams = (
  entityName: string,
  resourceLocation: ResourceLocation,
): Record<string, unknown> =>
  entityName === ResourceItem.entityName
    ? {
        resource_location_guid: resourceLocation.guid,
        status: 'available',
      }
    : { outer_location_guid: resourceLocation.guid };

const ResourceLocationMapModalContentRightList: React.FC<
  IResourceLocationMapModalContentRightListMapProps
> = ({
  entityName,
  onItemClick,
  resourceLocation,
  renderItem,
  selected,
  action,
  noResultsMessage,
}) => (
  <EntitySearch
    entityName={entityName}
    params={getParams(entityName, resourceLocation)}
    action={action}
    noResultsMessage={noResultsMessage}
  >
    {({ entities }) => (
      <List id="resource-item-list">
        {entities.map((entity) => {
          const id = `${entity.entityName}-${entity.id}`;
          const position =
            resourceLocation.map_data.data[id] &&
            resourceLocation.map_data.data[id].item;
          const info: InfoInterface = { name: entity.name };
          return (
            <div
              className={classnames({
                [styles.selected]: selected === id,
              })}
              draggable={!position}
              unselectable={!position ? 'on' : 'off'}
              key={entity.id}
              id={id}
              onClick={() => {
                onItemClick(id, info);
              }}
              // this is a hack for firefox
              // Firefox requires some kind of initialization
              // which we can do by adding this attribute
              // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
              onDragStart={(e) => {
                const data: any = {
                  i: id,
                  ...info,
                };

                e.dataTransfer.setData('text', JSON.stringify(data));
              }}
            >
              {renderItem({ entity, position })}
            </div>
          );
        })}
      </List>
    )}
  </EntitySearch>
);

export default ResourceLocationMapModalContentRightList;
